<div class="details-form d-flex flex-wrap flex-row grid-gap-3">
    <div class="flex-1 flex-basis-min">
        <div class="form-group">
            <h5 translate>TYPE</h5>
            <div>
                {{ translate.instant(cs.processChannelType(channel).toUpperCase()) }}
            </div>
        </div>
        <div class="form-group" *ngIf="adaptiveChannel.is_enabled && adaptiveChannel.view_url">
            <h5 translate>PLAY_IN_BROWSER</h5>
            <div>
                <a href="javascript:void(0)" (click)="cs.openChannelPreview(adaptiveChannel)" class="btn btn-play btn-primary">
                    <fa-icon icon="play" size="xs"></fa-icon>
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="channel.is_enabled && adaptiveChannel.view_url">
            <h5 translate>PLAY_IN_VLC</h5>
            <div>
                <a [href]="fileUrl" download="{{ channel.name }}.vlc" class="btn btn-play btn-primary"><fa-icon icon="play" size="xs"></fa-icon></a>
            </div>
        </div>
        <div class="form-group" *ngIf="(adaptiveChannel || passThroughChannel)?.processingCluster as processingCluster">
            <h5 translate>PROCESSING_CLUSTER</h5>
            <div class="ellipsis">
                <zx-cluster
                    [model]="processingCluster"
                    [showOtherIcons]="true"
                    [showStatusIcon]="true"
                    [showStatusText]="false"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-cluster>
            </div>
        </div>
        <div *ngIf="channel.type === 'adaptive'">
            <div class="form-group" *ngIf="adaptiveChannel.status?.active_broadcaster">
                <h5 translate>ACTIVE_BROADCASTER</h5>
                <div class="ellipsis">
                    <zx-broadcaster
                        [model]="adaptiveChannel.status?.active_broadcaster"
                        [showTag]="false"
                        [showLink]="true"
                        [showStatusIcon]="false"
                        [clusterId]="adaptiveChannel.broadcaster_cluster_id"
                    ></zx-broadcaster>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="channel.mediaconnect">
            <h5 translate>REGION</h5>
            <div>
                <span>{{ getAWSRegionName(mediaConnectFlow.region) }}</span>
            </div>
        </div>
        <div class="form-group" *ngIf="channel.failover && (channel.failoverSource.failoverSources | filter : 'priority' : '2')?.length > 0">
            <h5>{{ "PREFERRED" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let fs of channel.failoverSource.failoverSources | filter : 'priority' : '2'">
                    <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="channel.failover && (channel.failoverSource.failoverSources | filter : 'priority' : '1')?.length > 0">
            <h5>{{ "SECONDARY" | translate }} {{ "SOURCES" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let fs of channel.failoverSource.failoverSources | filter : 'priority' : '1'">
                    <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="channel.failover && (channel.failoverSource.failoverSources | filter : 'priority' : '0')?.length > 0">
            <h5>{{ "SLATE" | translate }} {{ "SOURCE" | translate }}</h5>
            <div>
                <div class="ellipsis" *ngFor="let fs of channel.failoverSource.failoverSources | filter : 'priority' : '0'">
                    <zx-source [model]="fs.source" [showLink]="true" [showTag]="false"></zx-source>
                </div>
            </div>
        </div>
        <div *ngIf="channel.type === 'adaptive'">
            <div class="form-group" *ngIf="adaptiveChannel.is_transcoding">
                <h5 translate>SOURCE</h5>
                <div class="ellipsis">
                    <zx-source
                        [model]="adaptiveChannel.bitrates[0].source"
                        [showStatusIcon]="true"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-source>
                </div>
            </div>
        </div>
        <div *ngIf="channel.type === 'adaptive'">
            <div class="form-group" *ngIf="adaptiveChannel.is_transcoding && adaptiveChannel.slateSource">
                <h5 translate>SLATE_SOURCE</h5>
                <div class="ellipsis">
                    <zx-source
                        [model]="adaptiveChannel.slateSource"
                        [showStatusIcon]="true"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-source>
                </div>
            </div>
        </div>
        <div *ngIf="ChannelsTypeGuard.isDelivery(channel)">
            <div class="form-group" *ngIf="passThroughChannel.target_broadcaster_id <= 0">
                <h5>{{ "TARGET_BROADCASTER_S" | translate }}</h5>
                <div>
                    <span *ngIf="passThroughChannel.target_broadcaster_id === 0">{{ "ANY" | translate }}</span>
                    <span *ngIf="passThroughChannel.target_broadcaster_id === -2">{{ "PRIMARY_BROADCASTERS_ONLY" | translate }}</span>
                    <span *ngIf="passThroughChannel.target_broadcaster_id === -3">{{ "BACKUP_BROADCASTERS_ONLY" | translate }}</span>
                </div>
            </div>
            <div class="form-group" *ngIf="passThroughChannel.target_broadcaster_id > 0">
                <h5>{{ "TARGET_BROADCASTER_S" | translate }}</h5>
                <div>
                    <zx-broadcaster
                        *ngIf="targetBroadcaster"
                        [model]="targetBroadcaster"
                        [showTag]="false"
                        [showLink]="true"
                        [showStatusIcon]="true"
                        [clusterId]="passThroughChannel.broadcaster_cluster_id"
                    ></zx-broadcaster>
                </div>
            </div>

            <div class="form-group" *ngIf="ChannelsTypeGuard.isDelivery(channel) && primarySources > 0">
                <h5>{{ "PRIMARY" | translate }} {{ "SOURCES" | translate }}</h5>
                <div>
                    <div class="ellipsis" *ngFor="let source of channel.sources">
                        <zx-source *ngIf="source.primary" [model]="source.source" [showLink]="true" [showTag]="false"></zx-source>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="ChannelsTypeGuard.isDelivery(channel) && backupSources > 0">
                <h5>{{ "BACKUP" | translate }} {{ "SOURCES" | translate }}</h5>
                <div>
                    <div class="ellipsis" *ngFor="let source of channel.sources">
                        <zx-source *ngIf="!source.primary" [model]="source.source" [showLink]="true" [showTag]="false"></zx-source>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="channel.mediaconnect">
            <div class="form-group" *ngIf="mediaConnectFlow.source">
                <h5 translate>MEDIACONNECT_SOURCE</h5>
                <div class="ellipsis">
                    <zx-mediaconnect-source
                        [model]="mediaConnectFlow.source"
                        [showStatusIcon]="true"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-mediaconnect-source>
                </div>
            </div>
            <div class="form-group">
                <h5 translate>MEDIACONNECT_FLOW</h5>
                <div>
                    <span>
                        <a [href]="cs.getFlowLink(channel)" target="_blank"
                            >{{ cs.getFlowName(channel) }} <fa-icon icon="external-link-alt" size="sm"></fa-icon
                        ></a>
                    </span>
                </div>
            </div>
            <div class="form-group" *ngIf="channel.description">
                <h5 translate>DESCRIPTION</h5>
                <div>
                    <span>
                        {{ channel.description }}
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="channel.medialive">
            <div class="form-group" *ngIf="mediaLiveChannel.source">
                <h5 translate>MEDIACONNECT_SOURCE</h5>
                <div class="ellipsis">
                    <zx-mediaconnect-source
                        [model]="mediaLiveChannel.source"
                        [showStatusIcon]="true"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-mediaconnect-source>
                </div>
            </div>
            <div class="form-group" *ngIf="mediaLiveChannel.flow">
                <h5 translate>MEDIACONNECT_FLOW</h5>
                <div class="ellipsis">
                    <zx-mediaconnect-flow
                        [model]="mediaLiveChannel.flow"
                        [showStatusIcon]="true"
                        [showStatusText]="false"
                        [showLink]="true"
                        [showTag]="false"
                    ></zx-mediaconnect-flow>
                </div>
            </div>
            <div class="form-group" *ngIf="mediaLiveChannel.arn">
                <h5 translate>MEDIALIVE_CHANNEL</h5>
                <div>
                    <span>
                        <a [href]="cs.getChannelLink(mediaLiveChannel)" target="_blank"
                            >{{ mediaLiveChannel.name }} <fa-icon icon="external-link-alt" size="sm"></fa-icon
                        ></a>
                    </span>
                </div>
            </div>
        </div>
        <div class="form-group" *ngIf="channel.altChannel">
            <h5>{{ "ALTERNATIVE_CHANNEL" | translate }}</h5>
            <div>
                <zx-adaptive *ngIf="channel.type === 'adaptive'" [model]="channel.altChannel" [showLink]="true" [showTag]="false"></zx-adaptive>
                <zx-delivery *ngIf="ChannelsTypeGuard.isDelivery(channel)" [model]="channel.altChannel" [showLink]="true" [showTag]="false"></zx-delivery>
                <zx-failover-channel
                    *ngIf="ChannelsTypeGuard.isFailover(channel)"
                    [model]="channel.altChannel"
                    [showLink]="true"
                    [showTag]="false"
                ></zx-failover-channel>
            </div>
        </div>
        <div class="form-group" *ngIf="channel.alertingProfile">
            <h5 translate>ALERTING_PROFILE</h5>
            <div>
                <a [routerLink]="['/' + urls.configuration.eventsManagement, channel.alertingProfile.id]">
                    {{ channel.alertingProfile.name }}
                </a>
            </div>
        </div>
        <div class="form-group" *ngIf="channel.type === 'adaptive' && adaptiveChannel.is_transcoding && adaptiveChannel.transcode_crop_mode !== 0">
            <h5 translate>CROP</h5>
            <div class="d-flex">
                <div class="me-2">
                    <label>{{ "X_LEFT" | translate }}:</label> {{ adaptiveChannel.transcode_crop_left
                    }}<span *ngIf="adaptiveChannel.transcode_crop_mode === 1">px</span><span *ngIf="adaptiveChannel.transcode_crop_mode === 2">%</span>
                </div>
                <div class="me-2">
                    <label>{{ "Y_TOP" | translate }}:</label> {{ adaptiveChannel.transcode_crop_top
                    }}<span *ngIf="adaptiveChannel.transcode_crop_mode === 1">px</span><span *ngIf="adaptiveChannel.transcode_crop_mode === 2">%</span>
                </div>
                <div class="me-2">
                    <label>{{ "WIDTH" | translate }}:</label> {{ adaptiveChannel.transcode_crop_width
                    }}<span *ngIf="adaptiveChannel.transcode_crop_mode === 1">px</span><span *ngIf="adaptiveChannel.transcode_crop_mode === 2">%</span>
                </div>
                <div>
                    <label>{{ "HEIGHT" | translate }}:</label> {{ adaptiveChannel.transcode_crop_height
                    }}<span *ngIf="adaptiveChannel.transcode_crop_mode === 1">px</span><span *ngIf="adaptiveChannel.transcode_crop_mode === 2">%</span>
                </div>
            </div>
        </div>
        <app-generic-detail
            *ngIf="channel.type === 'adaptive' && adaptiveChannel.is_transcoding"
            header="CLOSED_CAPTION_PRESERVE"
            [content]="adaptiveChannel.copy_closed_captions ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <!-- Failover Source -->
        <div *ngIf="channel.failover && channel.failoverSource">
            <app-generic-detail
                header="CONTENT_ANALYSIS"
                [content]="channel.failoverSource.content_analysis ? ('ENABLED' | translate) : ('DISABLED' | translate)"
            ></app-generic-detail>
            <app-generic-detail
                header="HITLESS_PRIORITY_UPGRADE"
                [content]="channel.failoverSource.allow_failover_priority_upgrade ? ('ENABLED' | translate) : ('DISABLED' | translate)"
            ></app-generic-detail>
            <app-generic-detail
                header="FAILOVER_MERGE_MODE"
                [content]="
                    channel.failoverSource.merge_mode === 'none'
                        ? ('NON_HITLESS' | translate)
                        : channel.failoverSource.merge_mode === 'rtp'
                        ? ('SMPTE_2022-7_HITLESS' | translate)
                        : channel.failoverSource.merge_mode === 'content'
                        ? ('CONTENT_HITLESS' | translate)
                        : ''
                "
            ></app-generic-detail>
            <app-generic-detail
                [header]="channel.failoverSource.merge_mode !== 'none' ? ('MERGE_SEARCH_WINDOW' | translate) : ('OFFLINE_FAILOVER_WINDOW' | translate)"
                [content]="channel.failoverSource.latency ?? ''"
                unit="ms"
            ></app-generic-detail>
            <!-- TODO: failover rules -->
        </div>

        <app-generic-detail
            *ngIf="channel.type === 'adaptive'"
            header="LOG_HLS_AD_MARKERS"
            [content]="adaptiveChannel.log_scte ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="channel.type === 'adaptive' && adaptiveChannel.is_transcoding"
            header="IGNORE_TRANSCODE_THRESHOLDS"
            [content]="adaptiveChannel.ignore_transcode_thresholds ? ('ENABLED' | translate) : ('DISABLED' | translate)"
        ></app-generic-detail>
        <app-generic-detail
            *ngIf="channel.type === 'adaptive'"
            header="MODE"
            [content]="adaptiveChannel.is_transcoding ? ('TRANSCODING' | translate) : ('EXISTING_SOURCES' | translate)"
        ></app-generic-detail>
        <div *ngIf="channel.type === 'adaptive'">
            <div class="form-group">
                <h5 translate>PROTOCOLS</h5>
                <div>
                    {{ protocols.join(", ") }}
                </div>
            </div>
            <app-generic-detail header="CHUNKS" [content]="adaptiveChannel.hls_chunks ? adaptiveChannel.hls_chunks : ''"></app-generic-detail>
            <app-generic-detail header="DURATION" [content]="adaptiveChannel.hls_chunk_duration ? adaptiveChannel.hls_chunk_duration : ''"></app-generic-detail>
        </div>
    </div>

    <div class="flex-1 flex-basis-min">
        <!-- Advanced Section of Form -->
        <div *ngIf="(channel.type === 'adaptive' && adaptiveChannel.is_transcoding) || channel.failover">
            <app-copy-credentials
                *ngIf="adaptiveChannel.billing_code || adaptiveChannel.billing_password || failoverChannel.billing_code || failoverChannel.billing_password"
                [user]="adaptiveChannel.billing_code || failoverChannel.billing_code"
                [password]="adaptiveChannel.billing_password || failoverChannel.billing_password"
                [showPasswordStrength]="false"
                [label]="'BILLING_CREDENTIALS' | translate"
                [userLabel]="'COPY_CODE' | translate"
                [canEdit]="canEdit"
            ></app-copy-credentials>
        </div>
        <div *ngIf="channel.type === 'adaptive' && adaptiveChannel.is_transcoding">
            <app-generic-detail header="ENCODER" [content]="adaptiveChannel.transcode_encoder ? encoderDisplayName : ''"></app-generic-detail>
            <app-generic-detail
                header="THREADS"
                [content]="adaptiveChannel.transcode_threads && adaptiveChannel.transcode_encoder === 'x264' ? adaptiveChannel.transcode_threads : ''"
            ></app-generic-detail>
        </div>
        <div *ngIf="channel.type === 'adaptive'">
            <app-generic-detail header="CONVERT_CEA708_TO_WEBVTT" [content]="adaptiveChannel.webvtt_cc ? ('ENABLED' | translate) : ''"></app-generic-detail>
            <div class="form-group">
                <h5 translate>TIMECODE_CONFIGURATION</h5>
                <div>
                    <span *ngIf="adaptiveChannel.timecode_mode === 'none'">{{ "NONE" | translate }}</span>
                    <span *ngIf="adaptiveChannel.timecode_mode === 'system'">{{ "SYSTEM_TIME" | translate }}</span>
                    <span *ngIf="adaptiveChannel.timecode_mode === 'sei'">{{ "SEI_TIMECODES" | translate }}</span>
                </div>
            </div>

            <app-generic-detail
                header="DISABLE_RENDITION_SYNC"
                [content]="adaptiveChannel.disable_synchronization ? ('YES' | translate) : ''"
            ></app-generic-detail>
            <app-generic-detail header="ADAPTIVE_STRICT_SEGMENTS" [content]="adaptiveChannel.strict_chunks ? ('ENABLED' | translate) : ''"></app-generic-detail>
            <app-generic-detail
                *ngIf="adaptiveChannel.is_transcoding"
                header="FORCE_I_FRAME_ON_SCTE"
                [content]="adaptiveChannel.force_i_frame_on_scte ? ('ENABLED' | translate) : ''"
            ></app-generic-detail>
            <app-generic-detail header="SEGMENT_ON_SCTE35" [content]="adaptiveChannel.segment_on_scte35 ? ('ENABLED' | translate) : ''"></app-generic-detail>
            <app-generic-detail header="SKIP_CUE_IN" [content]="adaptiveChannel.skip_cue_in ? ('ENABLED' | translate) : ''"></app-generic-detail>
            <app-generic-detail header="DROP_FIRST_SEGMENT" [content]="adaptiveChannel.drop_first_segment ? ('ENABLED' | translate) : ''"></app-generic-detail>
            <app-generic-detail header="SUPPORT_MULTIAUDIO" [content]="adaptiveChannel.support_multiaudio ? ('ENABLED' | translate) : ''"></app-generic-detail>
            <app-generic-detail
                header="ASCENDING_PLAYLIST_ORDER"
                [content]="adaptiveChannel.order_ascending ? ('ENABLED' | translate) : ''"
            ></app-generic-detail>
        </div>
        <app-generic-detail
            *ngIf="ChannelsTypeGuard.isDelivery(channel) || channel.failover"
            header="EXTERNAL_ID"
            [content]="channel.external_id ?? ''"
        ></app-generic-detail>

        <!-- Error Concealment -->
        <div *ngIf="channel.failover && channel.failoverSource && channel.failoverSource.error_concealment">
            <h4 translate>ERROR_CONCEALMENT</h4>
            <app-generic-detail
                header="ERROR_CONCEALMENT"
                [content]="channel.failoverSource.error_concealment ? ('ENABLED' | translate) : ''"
            ></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_CONTINUOUS_TIMELINE"
                [content]="channel.failoverSource.error_concealment_continuous_timeline ? ('ENABLED' | translate) : ''"
            ></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_REPLACE_FRAMES"
                [content]="channel.failoverSource.error_concealment_replace_frames ? ('ENABLED' | translate) : ''"
            ></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_FIX_CC"
                [content]="channel.failoverSource.error_concealment_fix_cc ? ('ENABLED' | translate) : ''"
            ></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_DELAY"
                unit="ms"
                [content]="channel.failoverSource.error_concealment_delay_ms ?? ''"
            ></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_CBR_PADDING"
                unit="kbps"
                [content]="channel.failoverSource.error_concealment_cbr_padding_kbps ?? ''"
            ></app-generic-detail>
            <app-generic-detail
                header="ERROR_CONCEALMENT_CBR_PADDING_PCR_INTERVAL"
                unit="ms"
                [content]="channel.failoverSource.error_concealment_cbr_padding_pcr_interval_ms ?? ''"
            ></app-generic-detail>
        </div>
    </div>
</div>
