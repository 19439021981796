<!-- Date Picker -->
<label for="datePicked" translate>{{ label }} <fa-icon *ngIf="required" icon="asterisk" size="xs"></fa-icon> </label>
<div class="form-group">
    <div class="form-group mb-0 position-relative">
        <div class="input-group">
            <input
                class="form-control minw-200px"
                id="datePicked"
                name="datePicked"
                (click)="togglePicker()"
                [value]="datePicked"
                placeholder="{{ 'SELECT_DATE' | translate }}"
                (keydown)="onlyDelete($event)"
                autocomplete="off"
                [ngClass]="{ 'is-invalid': invalid }"
                [disabled]="disabled"
                (change)="dateChanged($event.target.value)"
            />
            <button
                class="btn btn-outline-primary"
                (click)="clearDate()"
                type="button"
                [disabled]="disabled"
                title="{{ 'CLEAR_DATE' | translate }}"
                *ngIf="datePicked"
            >
                <fa-icon icon="times"></fa-icon>
            </button>
            <button
                class="btn btn-outline-primary"
                (click)="togglePicker()"
                [disabled]="disabled"
                type="button"
                title="{{ 'SELECT_DATE' | translate }}"
                *ngIf="!datePicked"
            >
                <fa-icon icon="calendar-alt" size="sm"></fa-icon>
            </button>
        </div>
        <div class="picker-container absolute" *ngIf="showPicker" (clickOutside)="clickOutsidePicker()">
            <div class="arrow"></div>
            <div class="picker-content shadow-sm">
                <fa-icon icon="times-circle" class="close-icon" (click)="closePicker()"></fa-icon>
                <zx-date-time-picker
                    name="datePicker"
                    [(ngModel)]="datePicked"
                    [allDates]="allDates"
                    [disabled]="disabled"
                    [onlyFutureDates]="onlyFutureDates"
                    [onlyPastDates]="onlyPastDates"
                    (ngModelChange)="dateChanged($event)"
                ></zx-date-time-picker>
            </div>
        </div>
    </div>
    <app-input-errors-indicator *ngIf="invalid" [errors]="ngControl.errors" [fieldName]="label" [invalid]="invalid"></app-input-errors-indicator>
</div>
