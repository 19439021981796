<zx-zec
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'zec'"
></zx-zec>
<zx-feeder
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'feeder'"
></zx-feeder>
<zx-cluster
    *ngIf="GridObjectsTypeGuard.type.isCluster(object)"
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
></zx-cluster>
<zx-broadcaster
    *ngIf="GridObjectsTypeGuard.type.isBroadcaster(object)"
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
></zx-broadcaster>
<zx-receiver
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="object.type === 'receiver'"
></zx-receiver>
<zx-source
    *ngIf="GridObjectsTypeGuard.type.isSource(object)"
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
></zx-source>
<zx-mediaconnect-source
    *ngIf="GridObjectsTypeGuard.type.isMediaConnectSource(object)"
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
></zx-mediaconnect-source>
<zx-adaptive
    *ngIf="GridObjectsTypeGuard.type.isAdaptiveChannel(object)"
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
></zx-adaptive>
<zx-delivery
    *ngIf="GridObjectsTypeGuard.type.isDeliveryChannel(object)"
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
></zx-delivery>
<zx-failover-channel
    *ngIf="GridObjectsTypeGuard.type.isFailoverChannel(object)"
    [model]="object"
    [showOtherIcons]="false"
    [showStatusIcon]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
></zx-failover-channel>
<zx-mediaconnect-flow
    *ngIf="GridObjectsTypeGuard.type.isMediaConnectFlow(object)"
    [model]="object"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
></zx-mediaconnect-flow>
<!-- 11 Types -->
<zx-target
    [model]="{ target: object, apiType: targetsService.getTargetApiType(object) }"
    [showStatusIcon]="false"
    [showOtherIcons]="false"
    [showStatusText]="false"
    [showLink]="true"
    [showTag]="false"
    [searchTerm]="searchTerm"
    *ngIf="sharedService.isTarget(object)"
></zx-target>
