import { ChangeDetectorRef, Component, Input, inject } from "@angular/core";
import { NgControl } from "@angular/forms";
import moment from "moment";
import { Constants } from "src/app/constants/constants";
import { CustomControlValueAccessor } from "../custom-control-value-accessor/custom-control-value-accessor";
import { TimeZoneService, dateFormats } from "src/app/services/time-zone.service";
@Component({
    selector: "app-zx-date-picker",
    templateUrl: "./zx-date-picker.component.html",
    styleUrls: ["./zx-date-picker.component.scss"]
})
export class ZxDatePickerComponent extends CustomControlValueAccessor<string> {
    @Input() label: string;
    @Input() isParentFormSubmitted: boolean;
    @Input() allDates? = false;
    @Input() onlyFutureDates? = false;
    @Input() onlyPastDates? = false;
    @Input() maxView = "year";
    @Input() minView = "hour";
    @Input() minuteStep = 5;

    disabled = this.ngControl.disabled;
    private datePickCounter = 0;
    showPicker: boolean;
    datePicked: string;

    private timeZoneService = inject(TimeZoneService);
    private constants = Constants;

    constructor(public ngControl: NgControl, private cdr: ChangeDetectorRef) {
        super(ngControl);
    }

    togglePicker() {
        this.showPicker = true;
        this.cdr.detectChanges();
    }

    clearDate() {
        this.datePicked = null;
        this._onChange(null);
    }

    // Prevent key events except delete and backspace
    onlyDelete(event: KeyboardEvent) {
        event.preventDefault();
    }

    closePicker() {
        this.datePickCounter = 0;
        this.showPicker = false;
    }

    clickOutsidePicker() {
        this.datePickCounter = this.datePickCounter + 1;
        if (this.datePickCounter > 1) {
            this.closePicker();
        }
    }

    dateChanged(event: string) {
        if (event !== null) {
            const date = moment.parseZone(event);
            this._onChange(date.toISOString());
            const format = this.timeZoneService.getDateTimeFormat(dateFormats.longDateTimeSeconds);
            this.datePicked = date.format(format);
        }
    }

    // Accepts value from the form control
    // eslint-disable-next-line
    writeValue(dateISO: string) {
        if (dateISO) {
            const timeZone = this.timeZoneService.selectedTimeZone();
            const format = this.timeZoneService.getDateTimeFormat(dateFormats.longDateTimeSeconds);
            const date = moment.tz(dateISO, timeZone?.utc[0] ?? Intl.DateTimeFormat().resolvedOptions().timeZone);
            this.datePicked = date.format(format);
        } else {
            this.datePicked = "";
        }
    }
}
