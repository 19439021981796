import { ActiveBroadcaster, ActiveState, Note, ObjectState, ZixiPlus, Source } from "../../../../models/shared";
import { FailoverChannel } from "../../../../pages/channels/channel";
import { Customer } from "../../../../pages/accounts/customer";
import { LiveEventAction, STAGE } from "@zixi/models";

export interface LiveEventStage {
    live_event_id: number;
    name: string;
    duration: number | null;
    force_channel_slate: boolean;
    clip_id: string | null;
}

export interface LiveEventClip {
    id: number;
    live_event_id: number;
    source_id: number;
    url: string;
    name: string;
    source: Source;
}

export type LiveEventActionFront = Omit<LiveEventAction, "original_time" | "scheduled_time"> & {
    original_time: string | null;
    scheduled_time: string | null;
};

export class LiveEvent extends ZixiPlus {
    _frontData?: {
        sortableStatus: string;
        lastRefresh: string;
        rowLoading?: boolean;
        rowSuccess?: boolean;
    };
    id: number;
    muted: number;
    muted_until: string;
    name: string;
    start_time: string;
    pre_live_duration_minutes: number;
    live_duration_minutes: number;
    post_live_duration_minutes: number;
    stage: STAGE;
    staging_mode: string;
    disable_channel_after_done: boolean;
    failoverChannels: FailoverChannel[];
    active_states: ActiveState[];
    object_state: ObjectState;
    customer: Customer;
    alerting_profile_id: number;
    note: Note;
    active_broadcaster_objects: ActiveBroadcaster;
    created_at: string;
    updated_at: string;
    hasFullDetails?: boolean;
    is_slate_locked: boolean;
    actions?: LiveEventActionFront[];
    stages?: LiveEventStage[];
    clips?: LiveEventClip[];
}
